import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Seo from '../components/seo';
import Image from '../components/image';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import validator from 'validator';
import parse from 'html-react-parser';
import { Person, LockOff, Mention, Question } from 'akar-icons';

const RegisterPage = () => {
    const [data, setData] = useState({
        name: '',
        email: '',
        password: '',
        answer: '',
    });
    const [dataClass, setDataClass] = useState({
        name: 'login__input',
        email: 'login__input',
        password: 'login__input',
        answer: 'login__input',
    });
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });

    const handleChange = (e) => {
        if (e) {
            setData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));

            if (dataClass[e.target.name] === 'login__input inputerror') {
                setDataClass((prevState) => ({
                    ...prevState,
                    [e.target.name]: 'login__input',
                }));

                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: false,
                }));
            }
        }
    };

    const handleRegister = (e) => {
        if (e) {
            e.preventDefault();

            let _error = false;

            if (data.name === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    name: 'login__input inputerror',
                }));

                _error = true;
            }

            if (data.email === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    email: 'login__input inputerror',
                }));

                _error = true;
            } else {
                if (validator.isEmail(data.email) === false) {
                    setDataClass((prevState) => ({
                        ...prevState,
                        email: 'login__input inputerror',
                    }));

                    setMessageSetting((prevState) => ({
                        ...prevState,
                        showMessage: true,
                        messageClass: 'dialog dialog--error',
                        messageContent: 'Please use a valid email address.',
                    }));

                    _error = true;
                }
            }

            if (data.password === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    password: 'login__input inputerror',
                }));

                _error = true;
            } else {
                if (validator.isStrongPassword(data.password) === false) {
                    setDataClass((prevState) => ({
                        ...prevState,
                        password: 'login__input inputerror',
                    }));

                    setMessageSetting((prevState) => ({
                        ...prevState,
                        showMessage: true,
                        messageClass: 'dialog dialog--error',
                        messageContent: parse(
                            'Your password strength is weak, please make sure it consist of the following:<br /><ul><li>At least 8 characters long</li><li>At least 1 uppercase character</li><li>At least one lowercase character</li><li>At least 1 numerical character</li><li>At least 1 symbol</li></ul>'
                        ),
                    }));

                    _error = true;
                }
            }

            if (data.answer === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    answer: 'login__input inputerror',
                }));

                _error = true;
            }

            if (_error === false) {
                CustomFetch(
                    'register',
                    'POST',
                    data,
                    function (result) {
                        if (result.error === '') {
                            localStorage.setItem(
                                'dpTicketToken',
                                result.access_token
                            );

                            navigate('/verify');
                        } else {
                            setMessageSetting((prevState) => ({
                                ...prevState,
                                showMessage: true,
                                messageClass: 'dialog dialog--error',
                                messageContent: result.error,
                            }));
                        }
                    },
                    function (error) {
                        setMessageSetting((prevState) => ({
                            ...prevState,
                            showMessage: true,
                            messageClass: 'dialog dialog--error',
                            messageContent: error,
                        }));
                    }
                );
            }
        }
    };

    return (
        <div id="app">
            <Seo title="Register" description="Dawn Projects Ticket System" />
            <CustomMessage data={messageSetting} />
            <div className="landing">
                <div className="landing__title">
                    <Image src="dawn-projects-logo.svg" alt="Dawn Projects" />
                    <h1>Ticket System</h1>
                </div>
            </div>
            <div className="login">
                <div className="login__box">
                    <div className="formitem fw">
                        <label className="fi__label">
                            <input
                                name="name"
                                className={dataClass.name}
                                type="text"
                                placeholder=" "
                                value={data.name}
                                onChange={handleChange}
                            />
                            <span className="fi__span">Name</span>
                            <Person size={24} />
                        </label>
                    </div>
                    <div className="formitem fw">
                        <label className="fi__label">
                            <input
                                name="email"
                                className={dataClass.email}
                                type="text"
                                placeholder=" "
                                value={data.email}
                                onChange={handleChange}
                            />
                            <span className="fi__span">Email Address</span>
                            <Mention size={24} />
                        </label>
                    </div>
                    <div className="formitem fw">
                        <label className="fi__label">
                            <input
                                name="password"
                                className={dataClass.password}
                                type="password"
                                placeholder=" "
                                value={data.password}
                                onChange={handleChange}
                            />
                            <span className="fi__span">Password</span>
                            <LockOff size={24} />
                        </label>
                    </div>
                    <div className="formitem fw">
                        <label className="fi__label">
                            <input
                                name="answer"
                                className={dataClass.answer}
                                type="text"
                                placeholder=" "
                                value={data.answer}
                                onChange={handleChange}
                            />
                            <span className="fi__span">In which level is the Chevron Tech Café located?</span>
                            <Question size={24} />
                        </label>
                    </div>
                    <div className="formitem fw">
                        <button onClick={handleRegister} className="loginbtn">
                            Register
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
